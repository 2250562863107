var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("CoffeeChannelCard", {
        attrs: {
          src: _vm.content.image_url,
          title: _vm.content.title,
          href: _vm.content.id
            ? _vm.$customUrlScheme.page(
                "standalone",
                "url=/video/detail/" + _vm.content.id
              )
            : "",
          reward: _vm.content.reward,
          tiled: _vm.index > 0,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <div>
    <CoffeeChannelCard
      :src="content.image_url"
      :title="content.title"
      :href="
        content.id
          ? $customUrlScheme.page(
              'standalone',
              `url=/video/detail/${content.id}`
            )
          : ''
      "
      :reward="content.reward"
      :tiled="index > 0" />
  </div>
</template>

<script>
import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  props: {
    content: {
      type: Object,
      default: () => {
        return {
          src: '',
          title: '',
          href: '',
          reward: {}
        };
      }
    },

    index: {
      type: Number,
      default: 2
    }
  }
});
</script>
